import {
  Injectable,
  Output,
  EventEmitter,
  OnDestroy,
  signal,
} from '@angular/core';
import { v4 as uuidV4 } from 'uuid';
import {
  Asset,
  Maybe,
  AssetItem,
  Playlist,
  UpdateAssetInput,
  AssetType,
  AssetItemInput,
  GetMediaForMediaManagePageGQL,
  Media,
  ResourceType,
  PlaylistType,
  VideoAsset,
  UpdatePlaylistInput,
  SaveOption,
  CreatePlaylistInput,
} from '@designage/gql';
import { ActivityStatus } from '@desquare/enums';
import moment from 'moment';
import { SubSink } from 'subsink';
import {
  getISOTime,
  getDateTime,
  getLocalizedTime,
  getRandomString,
  arrayItemMove,
} from '@desquare/utils';
import { ToasterService } from '../toaster/toaster.service';
import { ApolloError } from '@apollo/client/errors';
import { environment } from '@desquare/environments';
import { Apollo } from 'apollo-angular';
import { LocalStorageService } from 'ngx-webstorage';
import { localStorageKeys } from '@desquare/constants';
import { IAssetItemInput, IPlaylistForm } from '@desquare/interfaces';
import { cloneDeep, filter } from 'lodash';
import { lastValueFrom } from 'rxjs';

type AssetItemContent = AssetItem & { webpUrl?: string };

interface IAsset extends Asset {
  profileId: string;
}

@Injectable({
  providedIn: 'root',
})
export class PlaylistEditorService implements OnDestroy {
  private subs = new SubSink();

  @Output() selectedAssetChanges = new EventEmitter<Maybe<Asset>>();
  @Output() duplicateStartTimeAssets = new EventEmitter<Asset[]>();
  @Output() editingSequencesChange = new EventEmitter<Asset[]>();

  @Output() assetMutating = new EventEmitter<boolean>();
  @Output() sequenceTouch = new EventEmitter<boolean>();
  @Output() closePickers = new EventEmitter();
  @Output() sequenceValidityChanges = new EventEmitter<boolean>();
  @Output() previewPlayToggleTriggered = new EventEmitter<boolean>();
  /** tell eventual playlist open lists to refresh */
  @Output() playlistSaveChanges = new EventEmitter<boolean>();
  @Output() editingAssetItemsChange = new EventEmitter<IAssetItemInput[]>();
  @Output() playlistScheduleChanges = new EventEmitter<boolean>();
  @Output() selectedTransition = new EventEmitter<string>();
  assetChangingMessage = '';

  newlyAddedAsset!: Asset;
  currentPlaylistStatus = signal<ActivityStatus>(ActivityStatus.ACTIVE);
  publishedPlaylistStatus = signal<ActivityStatus>(ActivityStatus.ACTIVE);
  addedAssetIds: string[] = [];
  duplicateStartTimeAssetsValue: Asset[] = [];
  pendingPlaylists: Playlist[] = [];
  galleryAssetItem!: AssetItem;

  private _selectedAssetsValue: Asset[] = [];
  playlistType: Maybe<PlaylistType>;
  get editingSequences() {
    return this._selectedAssetsValue;
  }
  set editingSequences(assets: Asset[]) {
    this._selectedAssetsValue = assets;
  }

  private _editingAssetItems: IAssetItemInput[] = [];
  get editingAssetItems() {
    return this._editingAssetItems;
  }

  set editingAssetItems(assetItems: IAssetItemInput[]) {
    this._editingAssetItems = assetItems;
    this.emitEditingAssetItemsChange();
  }

  private _asset!: Maybe<Asset>;
  get asset() {
    return this._asset;
  }

  set asset(value: Maybe<Asset>) {
    if ((this.asset === undefined && value) || this._asset !== value) {
      this._asset = value;
      this.selectedAssetChanges.emit(value);
    }
  }

  constructor(
    private toasterService: ToasterService,
    private localStorageService: LocalStorageService,
    private getMediaGQL: GetMediaForMediaManagePageGQL,
  ) {
    this.initVariables();
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initVariables() {
    this.pendingPlaylists =
      this.localStorageService.retrieve(localStorageKeys.PENDING_PLAYLISTS) ??
      [];
  }

  initSubscriptions() {
    this.subs.sink = this.localStorageService
      .observe(localStorageKeys.PENDING_PLAYLISTS)
      .subscribe((playlists) => {
        this.pendingPlaylists = playlists;
      });
  }

  fixAssetItemsMetadata(a: Asset) {
    a.content.forEach((i) => {
      if (i.type === AssetType.Video) {
        const v = i as VideoAsset;
        if ((!v.height || !v.width) && v.media?.metadata) {
          v.height = v.media.metadata.height;
          v.width = v.media.metadata.width;
          // duration?
        }
      }
    });
  }

  createAsset(profileId: string, showMessage = false) {
    this.assetChangingMessage = 'CREATING_SEQUENCE';
    this.assetMutating.emit(true);

    const newAsset: Asset = {
      id: uuidV4(),
      name: 'New sequence',
      content: [],
    };

    if (this.editingSequences.length > 0) {
      const previousStartTime =
        this.editingSequences[this.editingSequences.length - 1].startTime;
      const newTime = moment(getDateTime(previousStartTime)).add(1, 'hour');
      newAsset.startTime = getISOTime(newTime);
      newAsset.actualStartTime = `${getLocalizedTime(newTime)}:00`; // returns only HH:MM,
    } else {
      const newTime = moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      newAsset.actualStartTime = `${getLocalizedTime(newTime)}:00`;
    }

    this.editingSequences.push(newAsset);
    if (showMessage) {
      this.toasterService.success('CREATE_SEQUENCE_SUCCESS');
    }
    this.newlyAddedAsset = newAsset;
    this.addedAssetIds.push(newAsset.id);
    this.assetMutating.emit(false);
    this.updateAssetsEndTime();
    this.emitEditingSequencesChange();
  }

  updateAssetsEndTime() {
    // based from https://gist.github.com/onildoaguiar/6cf7dbf9e0f0b8684eb5b0977b40c5ad#gistcomment-3061281
    // sorts the assets by startTime, sets date to 0 to compare time in the same day
    const sortedAssets = this.editingSequences.sort((a, b) => {
      const aTime = a.actualStartTime || '00:00:00';
      const bTime = b.actualStartTime || '00:00:00';
      return aTime >= bTime ? 1 : -1;
    });

    // sets the end time of the asset with the start time of the next asset in the array
    sortedAssets.map((asset, index, array) => {
      if (asset.actualStartTime) {
        asset.actualEndTime = array[index + 1]
          ? array[index + 1].actualStartTime
          : '00:00:00';
        asset.endTime = array[index + 1] ? array[index + 1].startTime : null;
      }
      // sets the end time of the last asset with the start time of the first asset
      if (index === array.length - 1) {
        asset.actualEndTime = array[0] ? array[0].actualStartTime : '00:00:00';
        asset.endTime = array[0] ? array[0].startTime : null;
      }

      return asset;
    });

    this.editingSequences = [...sortedAssets];
    // console.log('this.editingSequences', this.editingSequences);
  }

  getDuplicateAssets() {
    // based from https://stackoverflow.com/a/34185036
    this.duplicateStartTimeAssetsValue = filter(
      this.editingSequences,
      (x) =>
        filter(this.editingSequences, (y) => y.startTime === x.startTime)
          .length > 1,
    );
    this.duplicateStartTimeAssets.emit(this.duplicateStartTimeAssetsValue);
    return !!this.duplicateStartTimeAssetsValue.length;
  }

  getInvalidStartTime() {
    const invalidStartTime = this.editingSequences.filter((x) => !x.startTime);
    return !!invalidStartTime.length;
  }

  deleteAsset(id: string, successMessage: string) {
    this.editingSequences = this.editingSequences.filter((x) => x.id !== id);
    this.assetMutating.emit(false);
    this.updateAssetsEndTime();
    this.emitEditingSequencesChange();
  }

  updateAssetDetails(asset: Asset) {
    const updatedAsset = this.editingSequences.find((x) => x.id === asset.id);
    if (updatedAsset) {
      updatedAsset.startTime = asset.startTime ? asset.startTime : null;
      updatedAsset.actualStartTime = asset.actualStartTime
        ? asset.actualStartTime
        : null;
      updatedAsset.name = asset.name;
      this.updateAssetsEndTime();
      this.sequenceTouch.emit(true);
      this.emitEditingSequencesChange();
    }
  }

  getEditingSequence() {
    return this.editingSequences;
  }
  emitEditingSequencesChange() {
    // console.log('emitEditingSequencesChange', this.editingSequences);

    this.editingSequencesChange.emit(this.editingSequences);
  }

  emitEditingAssetItemsChange() {
    this.editingAssetItemsChange.emit(this.editingAssetItems);
  }

  moveAssetItem(
    oldAssetIdx: number,
    newAssetIdx: number,
    oldItemIdx: number,
    newItemIdx: number,
  ) {
    arrayItemMove(
      oldItemIdx,
      newItemIdx,
      this._selectedAssetsValue[oldAssetIdx].content,
      this._selectedAssetsValue[newAssetIdx].content,
    );

    this.emitEditingSequencesChange();
  }

  /**
   *
   * @param contentId
   * @param assetId id of an Asset (= Sequence = AssetList)
   * @returns
   */
  getContentById(contentId: string, assetId: string) {
    const assetList = this.editingSequences.find((x) => x.id === assetId);
    return assetList?.content.find((x) => x.id === contentId);
  }

  /**
   * This function creates an AssetItem object from a Media object. so far this is
   * only used when getting the content from the media gallery component. The default
   * values used is based off the initForm() of asset-content-form component, this
   * is also where a content is created. There should be a more unified way to create
   * a content (AssetItem) in the future.
   *
   * @param mediaContent
   * @returns
   */
  toAssetItem(media: Media) {
    const assetItem: AssetItemContent = {
      id: getRandomString(),
      contentId: media.id,
      name: media.name,
      type: this.setAssetType(media.type),
      publicId: media.publicId,
      campaignEnd: null,
      campaignStart: null,
      days: null,
      duration: this.getMediaDuration(media),
      width: this.getMediaWidthAndHeight('width', media),
      height: this.getMediaWidthAndHeight('height', media),
      uri: media.secureUrl,
      sequence: null,
      transitionEffect: null,
      resizeCropMethod: null,
      webpUrl: undefined,

      // TODO: this is a temporary fix for the invariant error
      media: {
        ...media,
        metadata: {
          ...media.metadata,
          format: null,
        },
      },
      __typename: this.setContentTypeName(media.type),
    };
    console.log('transformed media', assetItem);

    return assetItem;
  }

  setAssetType(type: ResourceType) {
    switch (type) {
      case ResourceType.Image:
        return AssetType.Image;
      case ResourceType.Video:
        return AssetType.Video;
      case ResourceType.Raw:
        return AssetType.Html;
    }
  }

  getMediaDuration(media: Media) {
    if (media.type === ResourceType.Video) {
      const duration = media.metadata?.duration;
      return duration ? Math.round(duration * 1000) : 0;
    } else {
      // if (media.type === ResourceType.Image) {
      //
      return 10 * 1000; // the current default is 10sec.
      // return 60 * 1000; // 1 minute
      //(
      //moment(this.defaultTime).valueOf() -
      //moment(this.defaultTime).startOf('day').valueOf()
      //);
    }
  }
  getMediaWidthAndHeight(coord: string, media: Media) {
    if (
      media.type === ResourceType.Video ||
      media.type === ResourceType.Image
    ) {
      if (coord === 'width') {
        return media.metadata?.width;
      }
      if (coord === 'height') {
        return media.metadata?.height;
      }
    } else {
      null;
    }
  }

  setContentTypeName(type: ResourceType) {
    switch (type) {
      case ResourceType.Image:
        return 'ImageAsset';
      case ResourceType.Video:
        return 'VideoAsset';

      // TODO: handle case ResourceType.Raw (?)
      // at the moment we are not handling "Raw"

      default:
        return;
    }
  }

  async addContent(content: AssetItem, assetId: string, insertIndex?: number) {
    const asset = this.editingSequences.find((x) => x.id === assetId);
    if (asset) {
      if (
        (content.__typename === 'ImageAsset' ||
          content.__typename === 'VideoAsset') &&
        content.media &&
        !content.media.metadata
      ) {
        const newMedia = await this.refetchMedia(content.media.id);
        if (newMedia) {
          content.media = newMedia;
        }
      }
      const assetContent = this.modifyContentProperties(
        this.setContentWebpUrl(content),
      );
      if (asset.content) {
        if (insertIndex === undefined) {
          asset.content.push(assetContent);
        } else {
          asset.content.splice(insertIndex, 0, assetContent);
        }
      } else {
        asset.content = [assetContent];
      }
      this.emitEditingSequencesChange();
    }
  }

  async refetchMedia(id: string) {
    const ret = await lastValueFrom(
      this.getMediaGQL.fetch({ id }, { fetchPolicy: 'network-only' }),
    );
    return ret.data.media;
  }

  updateContentById(contentId: string, newContent: AssetItem, assetId: string) {
    const asset = this.editingSequences.find((x) => x.id === assetId);
    const index = asset?.content.findIndex((x) => x.id === contentId) || -1;
    if (index >= 0) {
      this.updateContent(index, newContent, assetId);
    }
  }

  updateContent(
    targetContentIndex: number,
    newContent: AssetItem,
    assetId: string,
  ) {
    const modifiedContent = this.modifyContentProperties(newContent);

    const asset = this.editingSequences.find((x) => x.id === assetId);
    if (asset) {
      const newContentModified: AssetItemInput = this.modifyContentProperties(
        this.setContentWebpUrl(newContent),
      );
      newContentModified.id = uuidV4();

      if (asset.content) {
        const oldContent: AssetItemInput = asset.content[targetContentIndex];

        // copy config fields from prev content
        newContentModified.campaignEnd = oldContent.campaignEnd;
        newContentModified.campaignStart = oldContent.campaignStart;
        newContentModified.days = oldContent.days;

        // keep old content's duration only if new and old content is an Image
        if (
          newContent.type === AssetType.Image &&
          oldContent.type === AssetType.Image
        ) {
          newContentModified.duration = oldContent.duration;
        } else if (
          newContent.type === AssetType.Image &&
          oldContent.type === AssetType.Video
        ) {
          newContentModified.duration = 10000; // default duration is 10 sec. or 10000 millisec.
        }

        // resizeCropMethod
        if (
          oldContent.type === newContent.type &&
          (oldContent.type === AssetType.Video ||
            oldContent.type === AssetType.Image) &&
          oldContent.resizeCropMethod
        ) {
          // note: supposedly newContentModified is of type AssetItem, but since
          // AssetItem does not have resizeCropMethod as its field, changed newContentModified's
          // type to IAssetItemInput instead due to its similarity but more importantly has
          // resizeCropMethod as a field
          // FIXME: AssetItem, AssetItemInput, IAssetItemInput needs revisiting and clarifying,
          // they seem to convey the same thing but its confusing which one to actually use
          newContentModified.resizeCropMethod = oldContent.resizeCropMethod;
        }

        asset.content[targetContentIndex] = newContentModified;
      } else {
        asset.content = [newContentModified];
      }
      this.emitEditingSequencesChange();
    }
  }

  /**
   * prepare an assetItem to be inserted into an asset (sequence)
   * @param content
   * @returns
   */
  modifyContentProperties(content: AssetItem) {
    const modifiedContent = cloneDeep(content);
    // The purpose of this function is to condition, adjust or modify
    // the content object properties before it goes into the content list

    // Remove as not needed as input
    if ('mediaList' in modifiedContent) {
      // eslint-disable-next-line dot-notation,@typescript-eslint/dot-notation
      delete modifiedContent['mediaList'];
    }

    // Set duration
    if (
      !modifiedContent.duration &&
      'media' in modifiedContent &&
      modifiedContent.media?.metadata &&
      modifiedContent.media.metadata.duration
    ) {
      // if modifiedContent doesn't have a duration then get it from metadata then convert to milliseconds
      modifiedContent.duration = Math.round(
        modifiedContent.media.metadata.duration * 1000,
      );
    }

    return modifiedContent;
  }

  /** set placeholder url property webpUrl */
  setContentWebpUrl(content: AssetItem) {
    const assetContent: AssetItemContent = content;
    let webpUrl: string;
    if (
      (content.type === AssetType.Image || content.type === AssetType.Video) &&
      'uri' in content &&
      content.uri
    ) {
      webpUrl = `${content.uri.substring(
        0,
        content.uri.lastIndexOf('.'),
      )}.webp`;
    } else {
      webpUrl = environment.assets.placeholderImage;
    }
    assetContent.webpUrl = webpUrl;

    return assetContent;
  }

  /** migrated to playlist store/engine */
  setSequenceValidity() {
    const invalidAssetContentDuration = this.editingSequences.find((asset) =>
      asset.content.find((content) =>
        'duration' in content ? content.duration === 0 : null,
      ),
    );

    const invalidAssetContentCampaignStart = this.editingSequences.find(
      (asset) =>
        asset.content.find(
          (content) =>
            !moment(content.campaignStart).isValid() && !!content.campaignStart,
        ),
    );

    const invalidAssetContentCampaignEnd = this.editingSequences.find((asset) =>
      asset.content.find(
        (content) =>
          !moment(content.campaignEnd).isValid() && !!content.campaignEnd,
      ),
    );

    this.sequenceValidityChanges.emit(
      !invalidAssetContentDuration &&
        !invalidAssetContentCampaignStart &&
        !invalidAssetContentCampaignEnd,
    );
  }

  addToPendingPlaylists(playlistToAdd: Playlist) {
    const playlistIndex = playlistToAdd.id
      ? this.pendingPlaylists.findIndex(
          (pendingPlaylist) =>
            pendingPlaylist.id && pendingPlaylist.id === playlistToAdd.id,
        )
      : this.pendingPlaylists.findIndex(
          (pendingPlaylist) =>
            !pendingPlaylist.id &&
            pendingPlaylist.profile?.id === playlistToAdd.profile?.id,
        );

    if (this.pendingPlaylists.length) {
      if (playlistIndex >= 0) {
        this.pendingPlaylists[playlistIndex] = playlistToAdd;
      } else {
        this.pendingPlaylists.push(playlistToAdd);
      }
    } else {
      this.pendingPlaylists = [playlistToAdd];
    }
    this.setPendingPlaylists(this.pendingPlaylists);
  }

  setPendingPlaylists(playlists: Playlist[]) {
    this.localStorageService.store(
      localStorageKeys.PENDING_PLAYLISTS,
      playlists,
    );
  }

  getCurrentPendingPlaylist(param: {
    profileId?: string;
    playlist?: Playlist;
  }) {
    const { profileId, playlist } = param;
    // profileId is used to find pending playlist when creating
    return playlist?.id
      ? this.pendingPlaylists.find(
          (pendingPlaylist) =>
            pendingPlaylist.id &&
            pendingPlaylist.id === playlist.id &&
            pendingPlaylist.updatedAt > playlist.updatedAt,
        )
      : this.pendingPlaylists.find(
          (pendingPlaylist) =>
            !pendingPlaylist.id && pendingPlaylist.profile?.id === profileId,
        );
  }

  deletePendingPlaylist(param: { profileId?: string; playlistId?: string }) {
    const { profileId, playlistId } = param;
    // profileId is used to find pending playlist when creating
    const playlistToRemove = playlistId
      ? this.pendingPlaylists.find(
          (pendingPlaylist) => pendingPlaylist.id === playlistId,
        )
      : this.pendingPlaylists.find(
          (pendingPlaylist) =>
            !pendingPlaylist.id && pendingPlaylist.profile?.id === profileId,
        );

    if (playlistToRemove) {
      this.pendingPlaylists.splice(
        this.pendingPlaylists.indexOf(playlistToRemove),
        1,
      );
      this.setPendingPlaylists(this.pendingPlaylists);
    }
  }

  /**
   * This function transforms the playlist form values into a shape for the GQL Mutation inputs
   * - Warning: the recommended use case for this function is for it to be only used right
   * before a mutation
   * - Since playlist form values is getting saved into apollo cache, this function could potentially
   * cause Invariant errors
   *
   * @param values
   * @param profileId
   * @returns
   */
  getGqlUpdateInput(values: IPlaylistForm, profileId: Maybe<string>) {
    const newForm = this.cleanupPlaylistFormValues(
      cloneDeep(values),
      profileId,
    );
    const { id, name, endDate, startDate, channelIds, assets } = newForm;
    const gqlInput: UpdatePlaylistInput = {
      id,
      name,
      endDate,
      startDate,
      channelIds,
      profileId,
      assets,
      saveOption: SaveOption.Update,
    };
    return gqlInput;
  }
  getGqlCreateInput(values: IPlaylistForm, profileId: string) {
    const newForm = this.cleanupPlaylistFormValues(
      cloneDeep(values),
      profileId,
    );
    const { name, endDate, startDate, channelIds, assets } = newForm;
    const gqlInput: CreatePlaylistInput = {
      profileId,
      name,
      endDate,
      startDate,
      channelIds,
      assets,
      saveOption: SaveOption.Update,
    };
    return gqlInput;
  }
  cleanupPlaylistFormValues(values: IPlaylistForm, profileId: Maybe<string>) {
    values.assets.forEach((asset) => {
      asset.content?.forEach((content: any) => {
        if (content) {
          if ('__typename' in content) {
            // eslint-disable-next-line dot-notation,@typescript-eslint/dot-notation
            delete content['__typename'];
          }
          if ('media' in content) {
            // eslint-disable-next-line dot-notation,@typescript-eslint/dot-notation
            delete content['media'];
          }
          if ('webpUrl' in content) {
            // eslint-disable-next-line dot-notation,@typescript-eslint/dot-notation
            delete content['webpUrl'];
          }
          if ('duration' in content) {
            content.duration = Math.round(content.duration);
          }
        }
        if (content.days && '__typename' in content.days) {
          // eslint-disable-next-line dot-notation,@typescript-eslint/dot-notation
          delete content.days['__typename'];
        }
        if (
          content.transitionEffect &&
          '__typename' in content.transitionEffect
        ) {
          // eslint-disable-next-line dot-notation,@typescript-eslint/dot-notation
          delete content.transitionEffect['__typename'];
        }
        if (
          content.resizeCropMethod &&
          '__typename' in content.resizeCropMethod
        ) {
          // eslint-disable-next-line dot-notation, @typescript-eslint/dot-notation
          delete content.resizeCropMethod['__typename'];
        }
      });

      // removes profile from the request
      if ('profile' in asset) {
        delete asset.profile;
      }
      if ('__typename' in asset) {
        delete asset.__typename;
      }
    });

    if (!values.startDate) {
      values.startDate = null;
    }

    values.endDate = values.endDate
      ? moment(values.endDate).endOf('day')
      : null;

    return values;
  }
}
